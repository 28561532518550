/* Modern datetime calendar style overrides*/
button.Calendar__yearText,
button.Calendar__monthText {
  color: #40a9ff;
}

.Calendar__yearSelectorWrapper::after {
  height: 3em !important;
}

/* react data table overrides */
.rdt_Table input[name='select-all-rows']:disabled {
  /* hides disabled checkboxes */
  opacity: 0;
}

.table-container > div:first-child {
  min-width: 100%;
  @media (max-width: $mobile-width) {
    width: max-content;
  }
}
.table-container.--sorting .rdt_TableHead {
  pointer-events: none;
}
.table-container.--disable-sort .rdt_TableHead .__rdt_custom_sort_icon__ svg {
  opacity: 0;
}
.rdt_Table span.__rdt_custom_sort_icon__.asc svg,
.rdt_Table span.__rdt_custom_sort_icon__.desc svg {
  opacity: 1;
  margin-top: 1px;
}

#table-scroll tr td:first-child,
#table-scroll th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}

tr td:first-child:before {
  content: '';
}

#table-scroll th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

#table-scroll th[scope='row'] {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  vertical-align: top;
}

#table-scroll th:not([scope='row']):first-child {
  left: 0;
  z-index: 3;
}

//react slide pane overrides{
.slide-pane__overlay {
  @media (max-width: $mobile-width) {
    z-index: 9999;
  }
}
